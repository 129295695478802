import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarSizePipe } from '@awread/core/users';

@Component({
  standalone: true,
  selector: 'atom-circle-image',
  imports: [RouterModule, AvatarSizePipe],
  templateUrl: './circle-image.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleImageAtom implements OnInit {
  @Input() user: any;
  @Input() size: 'l' | 'xl' | 'other' = 'xl';
  @Input() src = '/global-assets/logos/avatar.webp';
  constructor() { }

  ngOnInit(): void { }
}
