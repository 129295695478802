<div class="relative">
  <scroll-snap [scrollSnapConfig]="scrollSnapConfig" [arrayData]="data.sliders">
    <div #slideItem *ngFor="let item of data.sliders" [id]="item.id" class="slide-item p-6 md:p-9">
      <ng-container *ngIf="item.target; then thenTemplate; else elseTemplate"></ng-container>
      <ng-template #thenTemplate>
        <a [href]="item.href" [target]="'_blank'">
          <img [ngSrc]="item.src" [priority]="priority" width="1200" height="630" style="aspect-ratio: 40/21" class="w-img max-w-none" [alt]="item.alt" />
        </a>
      </ng-template>
      <ng-template #elseTemplate>
        <a [routerLink]="item.href" [target]="'_self'">
          <img [ngSrc]="item.src" [priority]="priority" width="1200" height="630" style="aspect-ratio: 40/21" class="w-img max-w-none" [alt]="item.alt" />
        </a>
      </ng-template>
    </div>
  </scroll-snap>
</div>

<a href="https://www.facebook.com/share/p/18DYRnpKmX/" target="_blank" rel="noopener noreferrer">
  <div role="alert" class="alert max-w-lg mx-auto text-xs flex pointer">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info h-6 w-6 shrink-0">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    <span>Server bị lỗi dữ liệu, tác giả hỗ trợ upload lại ảnh! </span>
  </div>
</a>