import { CurrentUser } from './../../models/current-user.model.js';
import { Injectable, afterNextRender } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CurrentUserState {
  avatar: string;
  userId: string;
  userNid: string;
  updatedAt: string;
  role: string;
  registerCredential: any;
  username: string;
  haveDialogNoti: boolean;
  haveNoti: boolean;
  socialConnect?: any;
  terms: boolean;
  name: string;
  serviceObj: any;
  email: string;
  phone: string;
}

export function createInitialState(): CurrentUserState {
  return {
    avatar: '',
    userId: '',
    userNid: '',
    updatedAt: '',
    registerCredential: null,
    role: '',
    terms: false,
    username: '',
    haveDialogNoti: false,
    haveNoti: false,
    name: '',
    phone: '',
    email: '',
    serviceObj: {}
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'current-user', resettable: true })
export class CurrentUserStore extends Store<CurrentUserState> {
  constructor() {
    super(createInitialState());
    afterNextRender(() => {
      this.update({ userId: localStorage.getItem('userId')?.length ? (localStorage.getItem('userId') as string) : undefined })
    })
  }

  updateCurrentUserAkita(user: CurrentUser) {
    return this.update((e) => ({ ...e, ...user }));
  }
}
